input:focus {
    border-color: #0a5c9c;
}

.Select__control:focus {
    border-color: #0a5c9c;
}

.Ui.Input input:focus,
.Ui.Input.focus input {
    color: #212121;
    box-shadow: none;
}

.Select__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Select__dropdown-indicator i {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
    padding: 5px 8px !important;
}

.Select__option--is-disabled {
    opacity: 0.3;
}

.Select__indicator-separator {
    display: none;
}

.Select__option {
    padding: 0 10px !important;
    min-height: 36px !important;
    line-height: 36px !important;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Select__multi-value {
    background: #0A5C9C !important;
    border-radius: 13px !important;
    color: white !important;
}

.Select__multi-value__label {
    color: white !important;
    padding-left: 12px !important;
}

.Select__multi-value__remove {
    background: none;
    border-radius: 0 !important;
}

.Select__multi-value__remove:hover {
    background: none !important;
    color: white !important;
    cursor: pointer !important;
}

.CalendarDay__selected_end::after {
    background: #0A5C9C42;
    content: "";
    width: 39px;
    height: 38px;
    position: absolute;
    top: 0;
    left: 0;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    z-index: -1;
}

.Select_menu {
    z-index: 100;

}
