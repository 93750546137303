@font-face {
    font-family: 'Icons';
    src: url('../fonts/icons/Icons.eot');
    src: url('../fonts/icons/Icons.eot#iefix') format('embedded.opentype'),
        url('../fonts/icons/Icons.woff2') format('woff2'),
        url('../fonts/icons/Icons.woff') format('woff'),
        url('../fonts/icons/Icons.ttf') format('truetype'),
        url('../fonts/icons/Icons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

i.Icon {
    display: inline-block;
    opacity: 1;
    margin: 0px;
    width: 1.18em;
    height: 1em;
    font-family: 'Icons';
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    speak-as: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.Icon.Warning.Sign:before {
    content: '\e800';
}

/* '' */
.Icon.Web.Circle:before {
    content: '\e801';
}

/* '' */
.Icon.Widgets:before {
    content: '\e802';
}

/* '' */
.Icon.Wordpress.Circle:before {
    content: '\e803';
}

/* '' */
.Icon.Youtube.Circle:before {
    content: '\e805';
}

/* '' */
.Icon.Academic.Cap:before {
    content: '\e806';
}

/* '' */
.Icon.Add:before {
    content: '\e807';
}

/* '' */
.Icon.Add.Chart:before {
    content: '\e808';
}

/* '' */
.Icon.Add.Circle.Outline:before {
    content: '\e809';
}

/* '' */
.Icon.Angle.Left.Skip:before {
    content: '\e80a';
}

/* '' */
.Icon.Angle.Down:before {
    content: '\e80b';
}

/* '' */
.Icon.Angle.Right.Skip:before {
    content: '\e80c';
}

/* '' */
.Icon.Angle.Up:before {
    content: '\e80d';
}

/* '' */
.Icon.Arrow.Down:before {
    content: '\e80e';
}

/* '' */
.Icon.Arrow.Left:before {
    content: '\e80f';
}

/* '' */
.Icon.Arrow.Right:before {
    content: '\e810';
}

/* '' */
.Icon.Arrow.Right.Skip:before {
    content: '\e811';
}

/* '' */
.Icon.Arrow.Up:before {
    content: '\e812';
}

/* '' */
.Icon.At:before {
    content: '\e813';
}

/* '' */
.Icon.Bell:before {
    content: '\e814';
}

/* '' */
.Icon.Bell.Outline:before {
    content: '\e815';
}

/* '' */
.Icon.Blogger.Circle:before {
    content: '\e816';
}

/* '' */
.Icon.Bookmark:before {
    content: '\e817';
}

/* '' */
.Icon.Bookmark.Outline:before {
    content: '\e818';
}

/* '' */
.Icon.Calendar:before {
    content: '\e819';
}

/* '' */
.Icon.Calendar.Marked:before {
    content: '\e81a';
}

/* '' */
.Icon.Caret.Down:before {
    content: '\e81b';
}

/* '' */
.Icon.Caret.Up:before {
    content: '\e81c';
}

/* '' */
.Icon.Chart.Square:before {
    content: '\e81d';
}

/* '' */
.Icon.Check:before {
    content: '\e81e';
}

/* '' */
.Icon.Check.Circle:before {
    content: '\e81f';
}

/* '' */
.Icon.Check.Circle.Outline:before {
    content: '\e820';
}

/* '' */
.Icon.Check.Multiple:before {
    content: '\e821';
}

/* '' */
.Icon.Check.Shield:before {
    content: '\e822';
}

/* '' */
.Icon.Checkbox.Marked:before {
    content: '\e823';
}

/* '' */
.Icon.Checkbox.Minus:before {
    content: '\e824';
}

/* '' */
.Icon.Checkbox.Unmarked:before {
    content: '\e825';
}

/* '' */
.Icon.Chevron.Left:before {
    content: '\e826';
}

/* '' */
.Icon.Chevron.Right:before {
    content: '\e827';
}

/* '' */
.Icon.Clock:before {
    content: '\e828';
}

/* '' */
.Icon.Comment:before {
    content: '\e829';
}

/* '' */
.Icon.Compare:before {
    content: '\e82a';
}

/* '' */
.Icon.Copy:before {
    content: '\e82b';
}

/* '' */
.Icon.Credit.Card:before {
    content: '\e82c';
}

/* '' */
.Icon.Dashboard.Book:before {
    content: '\e82d';
}

/* '' */
.Icon.Dislike:before {
    content: '\e82e';
}

/* '' */
.Icon.Dollar:before {
    content: '\e82f';
}

/* '' */
.Icon.Download:before {
    content: '\e830';
}

/* '' */
.Icon.Download.Process:before {
    content: '\e831';
}

/* '' */
.Icon.Drag:before {
    content: '\e832';
}

/* '' */
.Icon.Drag.Handle:before {
    content: '\e833';
}

/* '' */
.Icon.Ellipsis.Circle:before {
    content: '\e834';
}

/* '' */
.Icon.Ellipsis.Vertical:before {
    content: '\e835';
}

/* '' */
.Icon.Email:before {
    content: '\e836';
}

/* '' */
.Icon.Email.Outline.Send:before {
    content: '\e837';
}

/* '' */
.Icon.Emoji:before {
    content: '\e838';
}

/* '' */
.Icon.Emoji.Happy:before {
    content: '\e839';
}

/* '' */
.Icon.Emoji.Neutral:before {
    content: '\e83a';
}

/* '' */
.Icon.Expand:before {
    content: '\e83b';
}

/* '' */
.Icon.Emoji.Sad:before {
    content: '\e83c';
}

/* '' */
.Icon.Export:before {
    content: '\e83d';
}

/* '' */
.Icon.Eye:before {
    content: '\e83e';
}

/* '' */
.Icon.Eye.Off:before {
    content: '\e83f';
}

/* '' */
.Icon.Facebook.Business.Circle:before {
    content: '\e840';
}

/* '' */
.Icon.Facebook.Circle:before {
    content: '\e841';
}

/* '' */
.Icon.File:before {
    content: '\e842';
}

/* '' */
.Icon.Filter.List:before {
    content: '\e843';
}

/* '' */
.Icon.Filter.Search:before {
    content: '\e844';
}

/* '' */
.Icon.Flag:before {
    content: '\e845';
}

/* '' */
.Icon.Fullscreen:before {
    content: '\e846';
}

/* '' */
.Icon.Fullscreen.Exit:before {
    content: '\e847';
}

/* '' */
.Icon.Gear:before {
    content: '\e848';
}

/* '' */
.Icon.Glossary:before {
    content: '\e849';
}

/* '' */
.Icon.Gmb.Circle:before {
    content: '\e84a';
}

/* '' */
.Icon.Google:before {
    content: '\e84b';
}

/* '' */
.Icon.Google.Ads:before {
    content: '\e84c';
}

/* '' */
.Icon.Hashtag:before {
    content: '\e84d';
}

/* '' */
.Icon.Headset:before {
    content: '\e84e';
}

/* '' */
.Icon.Heart:before {
    content: '\e84f';
}

/* '' */
.Icon.Heart.Outline:before {
    content: '\e850';
}

/* '' */
.Icon.Help.Circle:before {
    content: '\e851';
}

/* '' */
.Icon.Help.Outline:before {
    content: '\e852';
}

/* '' */
.Icon.Hot:before {
    content: '\e853';
}

/* '' */
.Icon.Igtv:before {
    content: '\e854';
}

/* '' */
.Icon.Instagram.Circle:before {
    content: '\e855';
}

/* '' */
.Icon.Info.Circle:before {
    content: '\e856';
}

/* '' */
.Icon.Instagram.Stories.Circle:before {
    content: '\e857';
}

/* '' */
.Icon.Insights:before {
    content: '\e858';
}

/* '' */
.Icon.Like:before {
    content: '\e859';
}

/* '' */
.Icon.Link:before {
    content: '\e85a';
}

/* '' */
.Icon.Lightbulb:before {
    content: '\e85b';
}

/* '' */
.Icon.Linkedin.Circle:before {
    content: '\e85c';
}

/* '' */
.Icon.List.View:before {
    content: '\e85d';
}

/* '' */
.Icon.Lock:before {
    content: '\e85e';
}

/* '' */
.Icon.Logout:before {
    content: '\e85f';
}

/* '' */
.Icon.Mentions.Off:before {
    content: '\e860';
}

/* '' */
.Icon.Menu:before {
    content: '\e861';
}

/* '' */
.Icon.Minus:before {
    content: '\e862';
}

/* '' */
.Icon.Minus.Circle:before {
    content: '\e863';
}

/* '' */
.Icon.Note:before {
    content: '\e864';
}

/* '' */
.Icon.Objective:before {
    content: '\e865';
}

/* '' */
.Icon.Open.Book:before {
    content: '\e866';
}

/* '' */
.Icon.Panel:before {
    content: '\e868';
}

/* '' */
.Icon.Pause:before {
    content: '\e869';
}

/* '' */
.Icon.Pencil:before {
    content: '\e86a';
}

/* '' */
.Icon.Photo:before {
    content: '\e86b';
}

/* '' */
.Icon.Photo.Off:before {
    content: '\e86c';
}

/* '' */
.Icon.Pinterest.Circle:before {
    content: '\e86d';
}

/* '' */
.Icon.Play:before {
    content: '\e86e';
}

/* '' */
.Icon.Play.Circle:before {
    content: '\e86f';
}

/* '' */
.Icon.Play.Rectangle:before {
    content: '\e870';
}

/* '' */
.Icon.Plus.Circle:before {
    content: '\e871';
}

/* '' */
.Icon.Profile.Circle:before {
    content: '\e872';
}

/* '' */
.Icon.Radio.Marked:before {
    content: '\e873';
}

/* '' */
.Icon.Radio.Unmarked:before {
    content: '\e874';
}

/* '' */
.Icon.Reddit.Circle:before {
    content: '\e875';
}

/* '' */
.Icon.Refresh:before {
    content: '\e876';
}

/* '' */
.Icon.Remove:before {
    content: '\e877';
}

/* '' */
.Icon.Remove.Circle:before {
    content: '\e878';
}

/* '' */
.Icon.Remove.Shield:before {
    content: '\e879';
}

/* '' */
.Icon.Renew:before {
    content: '\e87a';
}

/* '' */
.Icon.Repost:before {
    content: '\e87b';
}

/* '' */
.Icon.Screen:before {
    content: '\e87c';
}

/* '' */
.Icon.Search:before {
    content: '\e87d';
}

/* '' */
.Icon.Search.Page:before {
    content: '\e87e';
}

/* '' */
.Icon.Send:before {
    content: '\e87f';
}

/* '' */
.Icon.Share:before {
    content: '\e880';
}

/* '' */
.Icon.Shopping.Cart:before {
    content: '\e881';
}

/* '' */
.Icon.Sort.Ascending:before {
    content: '\e882';
}

/* '' */
.Icon.Sort.Descending:before {
    content: '\e883';
}

/* '' */
.Icon.Standby:before {
    content: '\e884';
}

/* '' */
.Icon.Star:before {
    content: '\e885';
}

/* '' */
.Icon.Star.Circle:before {
    content: '\e886';
}

/* '' */
.Icon.Star.Outline:before {
    content: '\e887';
}

/* '' */
.Icon.Stop:before {
    content: '\e888';
}

/* '' */
.Icon.Tag:before {
    content: '\e889';
}

/* '' */
.Icon.Team.Circle:before {
    content: '\e88a';
}

/* '' */
.Icon.Text:before {
    content: '\e88b';
}

/* '' */
.Icon.Toggle.Off:before {
    content: '\e88c';
}

/* '' */
.Icon.Toggle.On:before {
    content: '\e88d';
}

/* '' */
.Icon.Trash:before {
    content: '\e88e';
}

/* '' */
.Icon.Trending.Down:before {
    content: '\e88f';
}

/* '' */
.Icon.Trending.Flat:before {
    content: '\e890';
}

/* '' */
.Icon.Trending.Up:before {
    content: '\e891';
}

/* '' */
.Icon.Trophy:before {
    content: '\e892';
}

/* '' */
.Icon.Tumblr.Circle:before {
    content: '\e893';
}

/* '' */
.Icon.Twitter.Circle:before {
    content: '\e894';
}

/* '' */
.Icon.Unlink:before {
    content: '\e895';
}

/* '' */
.Icon.Upload:before {
    content: '\e896';
}

/* '' */
.Icon.Warning.Bubble:before {
    content: '\e897';
}

/* '' */
.Icon.Warning.Bubble.Outline:before {
    content: '\e898';
}

/* '' */
.Icon.Warning.Circle:before {
    content: '\e899';
}

/* '' */
.Icon.Warning.Circle.Outline:before {
    content: '\e89a';
}

/* '' */
.Icon.Warning.Decagon:before {
    content: '\e89b';
}

/* '' */
.Icon.Warning.Shield:before {
    content: '\e89c';
}

/* '' */
.Icon.Partner:before {
    content: '\e89d';
}

/* '' */
.Icon.Carousel:before {
    content: '\e89e';
}

/* '' */
.Icon.Meta.Business.Circle:before {
    content: '\e89f';
}

/* '' */
.Icon.Tiktok.Circle:before {
    content: '\e8a0';
}

/* '' */
.Icon.Load.Shield:before {
    content: '\e8a1';
}