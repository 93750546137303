.ExportLoadingArea {
    position: absolute;
    z-index: 99;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    .LoadingContentArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 360px;

        .ExportProgressBarArea {
            margin-top: 24px;
            width: 100%;
        }
    }
}

.ExportPhoneArea {
    position: absolute;
    z-index: 99;
    background-color: white;
    display: none;
    width: 100%;
    height: 100%;
    padding: 0px 34px;
    box-sizing: border-box;
}

.ExportArea {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #F5F5F5;
    height: 100%;
}

.ExportTopBar {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 3px #00000029;
    padding: 0px 24px;
    width: calc(100% - 48px);
    background: white;
    z-index: 10;
    flex-wrap: nowrap;
    white-space: nowrap;

    .ExportTopBarMiddleContent {
        display: flex;

        .ExportPaginationArea {
            display: flex;
            align-items: center;
            margin-right: 64px;

            .ExportPaginationButtons {
                margin-right: 24px;

                >* {
                    cursor: pointer;
                    padding: 0px 7px;
                }
            }
        }

        .ExportZoomArea {
            display: flex;
            align-items: flex-end;

            .ZoomButtonsArea {
                margin-right: 24px;

                >* {
                    padding: 0px 6px;
                    cursor: pointer;
                }
            }
        }

    }
}

.ExportContentArea {
    width: 100%;
    height: calc(100% - 56px);
    overflow: auto;
    position: relative;

    .ExportContent {
        height: 810px;
        width: 1440px;
        position: absolute;
        left: 50%;
        top: 50%;
    }
}

.ExportAnalyticsArea {
    height: 100%;
    overflow: hidden;
    position: relative;
}

.ExportCoverArea {
    .ExportBrandArea {
        display: flex;
        align-items: center;
        background-color: #0A5C9C14;
        max-width: 1050px;
        padding: 16px 0px 16px 24px;
        margin-top: 48px;
        margin-bottom: 106px;
    }

    .ExportProfileArea {
        margin-bottom: 24px;
    }

    .ExportBottomArea {
        margin-left: 24px;

        .ExportTitle {
            margin-bottom: 8px;
        }
    }
}

.ExportFullPage {
    display: flex;
    flex-direction: column;
    height: 810px;
    width: 1440px;
    position: relative;
    background: white;
}

.ExportBoxArea {
    position: relative;
    height: calc(100% - 140px);
}

.ExportFooter {
    position: relative;
    bottom: 0;
    background-color: #F5F5F5;
    padding: 8px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ExportBrandArea {
        margin-left: 24px;
        display: flex;
        align-items: center;
    }
}

.ExportHeader {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;

    .ExportTopHeader {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .ExportBottomHeader {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 24px 10px 0px 10px;
    }
}

.ExportTypeButtonArea {
    display: flex;
    align-items: center;
    border-radius: 136px;
}

.ExportTypeButtonArea:hover {
    background-color: #EEE;
}

.ExportIndex {
    padding-bottom: 40px;
    margin-left: 24px;
    height: 100%;

    .ExportIndexCategoryArea {
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        margin-top: 50px;
        margin-left: 118px;
        max-height: 500px;

        .ExportIndexCategory {
            display: flex;
            padding: 9px 50px;
            height: 20px;
        }
    }
}

.ExportProfileArea {
    display: flex;
    align-items: center;
    max-width: 1180px;
    flex-flow: wrap;

    .ExportProfile {
        margin: 12px;
        margin-right: 70px;
        display: flex;
        align-items: center;
        width: 150px;

        .ProfileLine {
            width: 4px;
            height: 36px;
            border-radius: 8px;
            margin-right: 8px;
        }
    }
}

.ExportDatesArea {
    display: flex;

    .VsArea {
        display: flex;
    }
}

.ExportSocialBackground.Instagram {
    background: #CD486B14;
}

.ExportSocialBackground.Facebook {
    background: #1877F214;
}

.ExportSocialBackground.Youtube {
    background: #FF000014;
}

.ExportSocialBackground.Twitter {
    background: #1DA1F214;
}

.ExportSocialBackground.Linkedin {
    background: #0077B514;
}

.ExportSocialBackground.Pinterest {
    background: #BD081C14;
}

.ExportSocialBackground.Reddit {
    background: #FF450014;
}

.ExportSocialBackground.Tumblr {
    background: #35465C14;
}

.ExportSocialBackground.Wordpress {
    background: #44444414;
}

.ExportSocialBackground.Web {
    background: #2EABE314;
}

@media screen and (max-width: 1024px) {
    .ExportPhoneArea {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .ExportTopBar {
        display: none;
    }
}
