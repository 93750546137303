html {
    width: auto !important;
    height: auto !important;
}

@font-face {
    font-family: "Neue Haas Grotesk";
    font-style: normal;
    font-weight: 800;
    src: url("../../resources/fonts/neueHaasGrotesk/NHaasGroteskTXPro-Rg.ttf");
}

@font-face {
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 400;
    src: url("../../resources/fonts/sofiaPro/SofiaProBold.woff2");
}


@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("../../resources/fonts/roboto/Roboto-300.eot");
    src: local("Roboto Light"), local("Roboto-Light"),
        url("../../resources/fonts/roboto/Roboto-300.eot?#iefix") format("embedded-opentype"),
        url("../../resources/fonts/roboto/Roboto-300.woff2") format("woff2"),
        url("../../resources/fonts/roboto/Roboto-300.woff") format("woff"),
        url("../../resources/fonts/roboto/Roboto-300.ttf") format("truetype"),
        url("../../resources/fonts/roboto/Roboto-300.svg#Roboto") format("svg");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("../../resources/fonts/roboto/Roboto.eot");
    src: local("Roboto"), local("Roboto-Regular"),
        url("../../resources/fonts/roboto/Roboto.eot?#iefix") format("embedded-opentype"),
        url("../../resources/fonts/roboto/Roboto.woff2") format("woff2"),
        url("../../resources/fonts/roboto/Roboto.woff") format("woff"),
        url("../../resources/fonts/roboto/Roboto.ttf") format("truetype"),
        url("../../resources/fonts/roboto/Roboto.svg#Roboto") format("svg");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("../../resources/fonts/roboto/Roboto-500.eot");
    src: local("Roboto Medium"), local("Roboto-Medium"),
        url("../../resources/fonts/roboto/Roboto-500.eot?#iefix") format("embedded-opentype"),
        url("../../resources/fonts/roboto/Roboto-500.woff2") format("woff2"),
        url("../../resources/fonts/roboto/Roboto-500.woff") format("woff"),
        url("../../resources/fonts/roboto/Roboto-500.ttf") format("truetype"),
        url("../../resources/fonts/roboto/Roboto-500.svg#Roboto") format("svg");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("../../resources/fonts/roboto/Roboto-700.eot");
    src: local("Roboto Bold"), local("Roboto-Bold"),
        url("../../resources/fonts/roboto/Roboto-700.eot?#iefix") format("embedded-opentype"),
        url("../../resources/fonts/roboto/Roboto-700.woff2") format("woff2"),
        url("../../resources/fonts/roboto/Roboto-700.woff") format("woff"),
        url("../../resources/fonts/roboto/Roboto-700.ttf") format("truetype"),
        url("../../resources/fonts/roboto/Roboto-700.svg#Roboto") format("svg");
}

html {
    font-family: "Roboto";
    margin: 0;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: "Roboto";
    margin: 0;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

div,
i,
button,
a,
td,
span,
img,
::before,
::after {
    box-sizing: content-box;
}

* {
    font-family: "Roboto", sans-serif;
    outline: 0px;
    scrollbar-width: thin;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
    white-space: pre-wrap;
    line-height: normal;
}

#root {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

@keyframes fade {
    from {
        opacity: 0.7;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 0.7;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 0.7;
    }

    50% {
        opacity: 0.3;
    }

    to {
        opacity: 0.7;
    }
}

*[data-skeleton="true"] {
    opacity: 0.7;
    animation: fade 1200ms infinite !important;
    user-select: none !important;
    filter: grayscale(1) !important;
    letter-spacing: -3px !important;
    color: #9e9e9e !important;
    background: #9e9e9e !important;
    cursor: wait !important;
    border-radius: 5px;

    >* {
        visibility: hidden;
    }
}

text[data-skeleton="true"],
*[data-skeleton="true"] text {
    display: inline !important;
    background-color: #9e9e9e !important;
    color: #9e9e9e !important;
    width: fit-content !important;
    cursor: wait !important;
}

text[data-skeleton="true"] *,
*[data-skeleton="true"] text * {
    color: #9e9e9e !important;
}

img[data-skeleton="true"],
*[data-skeleton="true"] img {
    position: relative !important;
    background-color: #9e9e9e !important;
}

img[data-skeleton="true"]::after,
*[data-skeleton="true"] img::after {
    content: "" !important;
    position: absolute !important;
    left: 0px !important;
    top: 0px !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 1 !important;
    background-color: #9e9e9e !important;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background-color: #EEEEEE;
}

::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
}

button {
    &:focus {
        outline: none !important;
    }

    &::-moz-focus-inner {
        outline: none !important;
    }
}

a {
    &:focus {
        outline: none !important;
    }

    &:active {
        outline: none !important;
    }
}

input[type="reset"] {
    &::-moz-focus-inner {
        outline: none !important;
    }
}

input[type="button"] {
    &::-moz-focus-inner {
        outline: none !important;
    }
}

input[type="submit"] {
    &::-moz-focus-inner {
        outline: none !important;
    }
}

select {
    &::-moz-focus-inner {
        outline: none !important;
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}

input[type="file"] {
    >input[type="button"] {
        &::-moz-focus-inner {
            outline: none !important;
        }
    }
}

a {
    outline: none;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: #212121;
}

button {
    outline: none;
    cursor: pointer;
    position: relative;
}

select {
    outline: none;
    cursor: pointer;
    position: relative;
}

textarea {
    outline: none;
    resize: none;
}

input {
    outline: none;
    resize: none;
}

highlight {
    background: rgba(14, 138, 232, 0.06);
    color: #0e8ae8;
}

iframe {
    width: 100% !important;
    height: 100% !important;
    border: 0px;
    display: block;
}

*[data-disable="true"] {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

*[data-disable="true"] * {
    pointer-events: none !important;
}

svg {
    touch-action: none;
}

.jvectormap-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    touch-action: none;
}

.jvectormap-tip {
    position: absolute;
    display: none;
    border: solid 1px #cdcdcd;
    border-radius: 3px;
    background: #292929;
    color: white;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
    display: none;
}

#popupSimulator {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 199;
}

.Icon.Reaction.Likes {
    background-image: url(https://fakecdn.swonkie.com/Resources/pre_images/competitors/Reaction/Like.png);
    background-size: contain;
    width: 18px !important;
    height: 18px !important;
}

.Icon.Reaction.Love {
    background-image: url(https://fakecdn.swonkie.com/Resources/pre_images/competitors/Reaction/Love.png);
    background-size: contain;
    width: 18px !important;
    height: 18px !important;
}

.Icon.Reaction.Wow {
    background-image: url(https://fakecdn.swonkie.com/Resources/pre_images/competitors/Reaction/Wow.png);
    background-size: contain;
    width: 18px !important;
    height: 18px !important;
}

.Icon.Reaction.Haha {
    background-image: url(https://fakecdn.swonkie.com/Resources/pre_images/competitors/Reaction/Haha.png);
    background-size: contain;
    width: 18px !important;
    height: 18px !important;
}

.Icon.Reaction.Sad {
    background-image: url(https://fakecdn.swonkie.com/Resources/pre_images/competitors/Reaction/Sorry.png?1);
    background-size: contain;
    width: 18px !important;
    height: 18px !important;
}

.Icon.Reaction.Angry {
    background-image: url(https://fakecdn.swonkie.com/Resources/pre_images/competitors/Reaction/Anger.png?1);
    background-size: contain;
    width: 18px !important;
    height: 18px !important;
}

.Icon.Google {
    background: -webkit-linear-gradient(-71deg, #ea4335 30%, #fbbc04 47%, #34a853 56%, #4285f4 75%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Icon.Facebook.Circle.Login {
    color: #1877f2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield !important;
    -moz-appearance: textfield !important;
}

.InsightsSwitcher:hover {

    .InsightsSwitcherTopArea {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .InsightsSwitcherBottomArea {
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
}

.InsightsSwitcher {
    width: 265px;
    position: absolute;
    z-index: 99;

    .InsightsSwitcherTopArea {
        padding: 12px 16px;
        background-color: #0F31F2;
        border-radius: 4px;
        box-shadow: 0px 9px 13px #00000029;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .InsightsSwitcherBottomArea {
        visibility: hidden;
        display: none;
        padding: 0px 16px 16px 16px;
        margin-top: -8px;
        background-color: #0F31F2;
        border-radius: 4px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}

.InsightsSwitcher.Bottom {
    left: 16px;
    bottom: 16px;
}

.InsightsSwitcher.Top {
    right: 16px;
    top: 55px;
}

.TourChecklistsFAB {
    width: 40px !important;
    height: 40px !important;
    border-radius: 12px !important;
    background: #FFD9E4 !important;
    position: relative;
}

.TourChecklistsFABUncheckedNumber {
    position: absolute;
    display: flex;
    width: 16px;
    height: 16px;
    padding: 1px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    background: #B3272A;
    top: -5px;
    right: -5px;
}

.TourChecklistsArea {
    display: flex;
    width: 288px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    background: #FAF9FD;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
    margin-bottom: 24px;
    animation-name: growfromfab;
    animation-duration: 0.5s;
    position: relative;
}

.TourChecklistsTopArea {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px 16px 0px 0px;
    border-bottom: 1px solid #C3C6CF;
    background: #FFD9E4;
}

.TourChecklistsProgressArea {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.TourChecklistsProgress {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px !important;
    flex: 1 0 0;
    border-radius: 16px;
    background: #FFF !important;
    height: 8px !important;
}

.TourChecklistsProgress>.MuiLinearProgress-bar {
    border-radius: 16px;
    background: #AF216C;
}

.TourChecklistsMiddleArea {
    display: flex;
    width: 288px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0px 0px 16px 16px;
    background: #FAF9FD;
}

.TourChecklistsItem {
    display: flex;
    height: 40px;
    padding: 0px 24px 0px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    font-size: 14px;
    border-bottom: 1px solid #C3C6CF;
    margin: 0px !important;
}

.react-joyride__overlay {
    z-index: 200 !important;
}

.TourChecklistsItem>.MuiFormControlLabel-label {
    width: 100%;
}

.MuiFormControlLabel-root.Mui-disabled {
    opacity: 0.4;
}

.TourChecklistsBottomArea {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
}

.swonkieMobileArea {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    z-index: 999;
    background: #EEEDF1;
    box-sizing: border-box;
}

.swonkieMobileContentArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #C3C6CF;
    background: #FAF9FD;
    padding: 24px 24px;
    gap: 16px;
    align-self: stretch;
}

.swonkieMobileTopArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.swonkieMobileBottomArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding-top: 24px;
}

.swonkieMobileBottomArea .MuiCheckbox-colorSecondary.Mui-checked {
    color: #0A5C9C;
}

@keyframes move {
    from {
        top: 300px;
    }

    to {
        top: 0px;
    }
}


@keyframes growfromfab{
    from{
        top: 210px;
        transform: scale(0.2);
        left: -110px;
    }
    to{
        top: 0px;
        transform: scale(1);
        left: 0px;
    }
}
